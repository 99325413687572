import { getAuth, getIdToken } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    // Your Firebase configuration object
};

// Initialize Firebase
initializeApp(firebaseConfig);

async function getIdentityToken() {
    if (process.env.NODE_ENV === 'development') {
        return 'fake-token';
    }

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
        throw new Error('User not authenticated');
    }
    const token = await getIdToken(user);
    return token;
}

export { getIdentityToken };
