import React, { useState, useEffect } from 'react';
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Edit, Delete, Save, Cancel } from '@mui/icons-material';
import axios from '../axiosConfig';


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    inputCell: {
        padding: theme.spacing(1),
    },
    addButton: {
        marginLeft: theme.spacing(1),
    },
}));


const Dashboard = () => {
    const classes = useStyles();
    const [retiredStocks, setRetiredStocks] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editingStock, setEditingStock] = useState(null);
    const [isRunningJob, setIsRunningJob] = useState(false);
    const [isDownloadingFile, setIsDownloadingFile] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchRetiredStocks();
    }, []);

    const handleForceUpdate = async () => {
        setIsRunningJob(true)
        try {
            await axios.post('/jobs/discover');
            // You can add a success message or additional logic here, if needed.
        } catch (error) {
            console.error('Error initiating force update:', error);
        }
        setIsRunningJob(false)
    };

    const handleDownloadBlocklist = async () => {
        setIsDownloadingFile(true)
        try {
            const response = await axios.get('/retired_stocks', {
                headers: {
                    Accept: 'text/plain',
                },
            });

            const blob = new Blob([response.data], { type: 'text/plain' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'blocklist.txt';
            link.click();
        } catch (error) {
            console.error('Error downloading blocklist:', error);
        }
        setIsDownloadingFile(false)

    };



    const fetchRetiredStocks = async () => {
        setLoading(true);
        const response = await axios.get('/retired_stocks', {
            headers: {
                Accept: 'application/json',
            },
        });
        setLoading(false);
        setRetiredStocks(response.data);
    };

    const handleEditStock = (stock) => {
        setEditMode(true);
        setEditingStock(stock);
    };

    const handleSaveStock = async () => {
        await axios.put(`/retired_stocks/${editingStock.id}`, editingStock);
        setEditMode(false);
        setEditingStock(null);
        fetchRetiredStocks();
    };

    const handleCancelEdit = () => {
        setEditMode(false);
        setEditingStock(null);
    };

    const handleDeleteStock = async (stock) => {
        await axios.delete(`/retired_stocks/${stock.id}`);
        fetchRetiredStocks();
    };

    const handleInputChange = (event, stock) => {
        const { name, value } = event.target;
        setEditingStock({ ...stock, [name]: value });
    };

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleForceUpdate}
                style={{ marginBottom: 16 }}
                disabled={isRunningJob}
            >
                FORCE UPDATE BLACKLIST
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleDownloadBlocklist}
                style={{ marginBottom: 16, marginLeft: 16 }}
                disabled={isDownloadingFile}
            >
                Download blocklist
            </Button>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </div>
            ) : (
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="retired stocks table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Symbol</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell>Discovery Date</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {retiredStocks.map((stock) => (
                                <TableRow key={stock.id} className={classes.tableRow}>
                                    {editMode && editingStock.id === stock.id ? (
                                        <>
                                            {Object.keys(stock).map((key) => (
                                                <TableCell key={key} className={classes.inputCell}>
                                                    <TextField
                                                        name={key}
                                                        value={editingStock[key]}
                                                        onChange={(event) => handleInputChange(event, stock)}
                                                    />
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                <IconButton onClick={handleSaveStock}>
                                                    <Save />
                                                </IconButton>
                                                <IconButton onClick={handleCancelEdit}>
                                                    <Cancel />
                                                </IconButton>
                                            </TableCell>
                                        </>
                                    ) : (
                                        <>
                                            {Object.values(stock).map((value, index) => (
                                                <TableCell key={index}>{value}</TableCell>
                                            ))}
                                            <TableCell>
                                                <IconButton onClick={() => handleEditStock(stock)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteStock(stock)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default Dashboard;

