import axios from "axios";
import { getIdentityToken } from "./googleAuth";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000",
});

instance.interceptors.request.use(async (config) => {
    try {
        const token = await getIdentityToken();
        config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
        console.error('Error getting Identity Token:', error);
    }
    return config;
});


export default instance;
